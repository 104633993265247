// 3rd-party Plugins
$reactTagColorText: #ffffff;
$modal-title-close-font-size: 1rem;
$modal-title-close-color: "gray";
$modal-title-close-hover-color: "darkgrey";
$tour-slider-phone-portrait-height: 450px;
$ad-slider-height: 200px;
$ad-slider-width: calc(#{$ad-slider-height} * 1 / 0.66);
$tour-slider-phone-portrait-width: calc(
  #{$tour-slider-phone-portrait-height} / 2.16
);
$navi-link-icon-font-size: 1rem;
$navi-link-icon-font-size-lg: 1.25rem;
$navi-link-icon-font-size-xl: 1.5rem;
@import "~@pathofdev/react-tag-input/src/styles/index.scss";
@import "~toastr/toastr";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~@pathofdev/react-tag-input/src/styles/index.scss";
//@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "~@feltapp/metronic/assets/sass/style.react.scss";

// Default Layout themes
@import "~@feltapp/metronic/assets/sass/themes/layout/header/base/light.scss";
@import "~@feltapp/metronic/assets/sass/themes/layout/header/menu/light.scss";
@import "~@feltapp/metronic/assets/sass/themes/layout/brand/dark.scss";
@import "~@feltapp/metronic/assets/sass/themes/layout/aside/dark.scss";
@import "~bootstrap/scss/bootstrap";

// Fonts
@font-face {
  font-family: "WorkSans";
  src: local("WorkSans"),
    url("./fonts/WorkSans/WorkSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "WorkSans-Light";
  src: local("WorkSans-Light"),
    url("./fonts/WorkSans/WorkSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "WorkSans-ExtraLight";
  src: local("WorkSans-ExtraLight"),
    url("./fonts/WorkSans/WorkSans-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "WorkSans-Bold";
  src: local("WorkSans-Bold"),
    url("./fonts/WorkSans/WorkSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "WorkSans-Medium";
  src: local("WorkSans-Medium"),
    url("./fonts/WorkSans/WorkSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "WorkSans-SemiBold";
  src: local("WorkSans-SemiBold"),
    url("./fonts/WorkSans/WorkSans-SemiBold.ttf") format("truetype");
}

/* CSS Simple Pre Code */
pre {
  white-space: pre-wrap;
  color: white;
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}
.string {
  color: white;
}
.max-w-400 {
  max-width: 400px;
}
.max-w-300 {
  max-width: 300px;
}
.number {
  color: greenyellow;
}
.boolean {
  color: magenta;
}
.null {
  color: orange;
}
.key {
  color: orange;
}

.modal-full {
  .modal-dialog {
    width: 90vw !important;
    max-width: 90vw !important;
  }
}

pre {
  background: #333;
  white-space: pre;
  word-wrap: break-word;
  overflow: auto;
}

.select {
  @extend .mr-3;
  width: 150px;
}
.recipient-modal {
  transition: transform 0.25s;

  &.translate {
    transform: translate(-270px, 0);
  }
}

pre.code {
  margin: 20px 25px;
  border-radius: 4px;
  border: 1px solid #292929;
  position: relative;
}

pre.code label {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: #ddd;
  position: absolute;
  left: 1px;
  top: 15px;
  text-align: center;
  width: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

pre.code code {
  background-color: unset !important;
  font-family: "Inconsolata", "Monaco", "Consolas", "Andale Mono",
    "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
  display: block;
  margin: 0 0 0 30px;
  padding: 15px 16px 14px;
  border-left: 1px solid #555;
  overflow-x: auto;
  font-size: 13px;
  line-height: 19px;
  color: #ddd;
}

.card-height-fluid {
  height: 100%;
}

.input-group {
  width: unset;
}

pre.code-css code {
  color: #91a7ff;
}

pre.code-html code {
  color: #aed581;
}

pre.code-javascript code {
  color: #ffa726;
}

pre.code-jquery code {
  color: #4dd0e1;
}

.scroll-y {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.scroll-y::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.h-100-px {
  max-height: 100px !important;
}
.h-75-px {
  height: 75px !important;
}
.h-50-px {
  max-height: 50px !important;
}
.h-25-px {
  max-height: 25px !important;
}
//@include media-breakpoint-up(md) {
//  .h-100-px {
//    max-height: 150px;
//  }
//}
.menu-arrow {
  font-size: 1.2rem !important;
}

ul {
  padding: 0;
  list-style-type: none;
}

.img-box {
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 10px;
}

.aside-menu .menu-nav .menu-item > .menu-heading,
.aside-menu .menu-nav .menu-item > .menu-link {
  align-items: center;
}

.aside-menu .menu-nav .menu-item > .menu-heading,
.aside-menu .menu-nav .menu-item .menu-item-open {
  & > .menu-link {
    .menu-arrow {
      transform: rotate(90deg);
    }
  }
}

.search-input {
  border: 0;
  &:focus {
    outline: none;
  }
}

.reservation-modal {
  max-height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
}

.file-dnd {
  padding: 5rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.Select-menu-outer {
  z-index: 10000;
}

.react-tag-input {
  padding: 0.3em;
  @extend .form-control;
  @extend .form-control-solid;
  @extend .form-control-lg;
  .react-tag-input__input {
    font-size: 1.08rem;
  }
  .react-tag-input__tag {
    display: inline-flex;
    background: rgba(54, 153, 255, 0.08);
    font-size: 1.08rem;
  }
  .react-tag-input__tag__remove {
    display: inline-flex;
    font-size: 1em;
    background: #3699ff;
    &:before,
    &:after {
      background-color: white;
    }
  }
}
.toast {
  background-color: #030303;
}
.toast-success {
  background-color: $success;
}
.toast-error {
  background-color: #bd362f;
}
.toast-info {
  background-color: $primary;
}
.toast-warning {
  background-color: #f89406;
}
// Header themes
// Light
//@import "./metronic/assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./metronic/assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./metronic/assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./metronic/assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
//.brand-dark {
//  @import "./metronic/assets/sass/themes/layout/brand/dark.scss";
//}
// Light
.brand-light {
  @import "~@feltapp/metronic/assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./metronic/assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./metronic/assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.selection-cell {
  width: 1px;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

// .nav-link:focus {
//     outline: none;
// }

// Tour Slider
.tour-slider-container {
  //display: flex;
  //flex: 1;
  //align-items: center;
  //justify-content: center;
}

.icon-picker-btn {
  @extend .btn;
  @extend .btn-outline-primary;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  border: 0 !important;
  width: 40px;
  height: 40px;
  &:hover {
    @extend .btn-primary;
  }

  &.active {
    @extend .btn-primary;
  }
}

.btn-icon {
  width: unset !important;
  height: unset !important;
}

.alice-carousel__stage-item {
  width: 100% !important;
}

.alice-carousel__wrapper {
  height: auto !important;
}

.alice-carousel__dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 100000;
}

.TOUR_SLIDER_PHONE_PORTRAIT {
  //width: $tour-slider-phone-portrait-width;
  width: 100%;
}

.TOUR_PHONE_PORTRAIT {
  //height: $tour-slider-phone-portrait-height;
  //object-fit: contain;
  width: 100%;
  max-height: $tour-slider-phone-portrait-height;
  object-fit: contain;
  object-position: center;
}

.AD_SLIDE_MOBILE {
  //height: $ad-slider-height;
  width: 100%;
}
.fc-button {
  @extend .btn;
}

.fc-button-primary {
  @extend .btn-primary;
}
.fc-h-event {
  background-color: transparent;
  border: 0;
}

.fc {
  .fc-daygrid-day {
    .fc-day-today {
      background-color: $primary-light;
    }
  }
}

.timeline-items.timeline-scroll {
  max-height: 350px;
  overflow-y: scroll;
}

body {
  background-color: rgb(238, 240, 248);
  font-family: WorkSans, sans-serif;
}

.header {
  background-color: transparent;
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
  padding-top: 60px;
}

///* Hide the popper when the reference is hidden */
//.popper[data-popper-reference-hidden] {
//  visibility: hidden;
//  pointer-events: none;
//}

.arrow,
.arrow::before {
  position: absolute;
  width: 40px;
  height: 40px;
  background: inherit;
  box-shadow: 0 2px 4px 0 rgba(14, 30, 37, 0.12);
  z-index: -1;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

.popper {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(14, 30, 37, 0.12);
}

//.popper[data-popper-placement^="top"] > #arrow {
//  bottom: -20px;
//}
//
//.popper[data-popper-placement^="bottom"] > #arrow {
//  top: -20px;
//}
//
//.popper[data-popper-placement^="left"] > #arrow {
//  right: -20px;
//}
//
//.popper[data-popper-placement^="right"] > #arrow {
//  left: -20px;
//}

form.form-nice {
  & .form-group {
    margin-bottom: -1rem;
    &:last-child {
      margin-bottom: 0;
    }
    & label {
      position: relative;
      top: 1.3rem;
      left: 0.5rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      background-color: white;
    }
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

div {
  font-family: "WorkSans", serif;
}

p {
  font-family: "WorkSans", serif;
}

.auth-container {
  border: 1px solid #3b3b3b;
  border-radius: 5px;
}

.bg-blue {
  background-color: rgb(10, 25, 31);
}

.bg-gold {
  background-color: rgb(230, 183, 89);
}

.btn-social {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  height: 38px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 10px;
  border-radius: 4px;
}

.btn-facebook {
  background-color: rgb(59, 89, 152);
  color: white;
  font-size: 1rem;
  font-family: "WorkSans-SemiBold", serif;
}

.btn-goog {
  background-color: white;
  color: black;
  font-family: "WorkSans-SemiBold", serif;
  font-size: 1rem;
  border: 1px solid black;
}

.btn-apple {
  background-color: white;
  border: 1px solid black;
  color: black;
  font-family: "WorkSans-SemiBold", serif;
  font-size: 1rem;
}

.text-blue {
  color: rgb(10, 25, 31);
}
