//
// Wizard 2
// Pages SASS files are compiled into separate css files
//

// Initialization of global variables, mixins and functions
@import "../../init";

// Variables
$wizard-nav-width: 400px;
$wizard-nav-width-sm: 325px;

// Base
.wizard.wizard-2 {
  display: flex;
  background-color: white;
  // Nav
  .wizard-nav {
    padding: 0;
    flex: 0 0 $wizard-nav-width;
    width: $wizard-nav-width;
    max-width: 100%;

    // Steps
    .wizard-steps {
      display: flex;
      flex-direction: column;
      justify-content: center;

      // Step
      .wizard-step {
        padding: 0.75rem 1.5rem;
        position: relative;
        border-radius: 0.5rem;
        transition: $transition-link;

        .wizard-wrapper {
          display: flex;
          align-items: center;
        }

        .wizard-icon {
          font-size: 2.5rem;
          margin-right: 1.1rem;
          transition: $transition-link;

          i {
            color: $text-muted;
          }

          .svg-icon {
            @include svg-icon-color($text-muted);
          }
        }

        .wizard-label {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .wizard-title {
            color: $dark;
            font-weight: 500;
            font-size: 1.1rem;
          }

          .wizard-desc {
            color: $dark-50;
          }
        }

        .wizard-number {
          @extend .btn;
          @extend .btn-outline-primary;
          @extend .mr-5;

          &:hover {
            @extend .btn-primary;
          }
        }

        .wizard-arrow {
          color: $dark-50;
          font-size: 1.25rem;

          &.last {
            display: none;
          }
        }

        &[data-wizard-state="current"] {
          background-color: $gray-100;
          transition: $transition-link;

          .wizard-number {
            @extend .btn-primary;
          }

          .wizard-icon {
            transition: $transition-link;

            i {
              color: $primary !important;
            }

            .svg-icon {
              @include svg-icon-color($primary, true);
            }
          }

          &:after {
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            content: " ";
            height: 0;
            width: 0;
            border: solid transparent;
            position: absolute;
            border-left-color: $gray-100;
            border-width: 1rem;
          }
        }
      }
    }
  }

  // Body
  .wizard-body {
    width: 100%;
  }
}

// Custom desktop range
@include media-breakpoint-between(lg, xl) {
  // Base
  .wizard.wizard-2 {
    // Nav
    .wizard-nav {
      flex: 0 0 $wizard-nav-width-sm;
      width: $wizard-nav-width-sm;
    }
  }
}

// Tablet and mobile mode
@include media-breakpoint-down(md) {
  .wizard.wizard-2 {
    display: block;

    // Nav
    .wizard-nav {
      width: 100%;
      border-bottom: 1px solid $border-color;

      .wizard-steps {
        .wizard-step {
          &[data-wizard-state="current"] {
            &:after {
              content: none;
            }
          }
        }
      }
    }

    // Body
    .wizard-body {
      width: 100%;
    }
  }
}
